import React from "react"
import SageTopLeft from "../images/SageTopLeft"
import SageBottomRight from "../images/SageBottomRight"

const WeddingVid = () => {
    return (
  <div className="w-full" style={{ minHeight: 600 }}>
    <div style={{ marginBottom: `1.45rem`, pointerEvents: 'none' }} className="absolute md:fixed w-60 md:w-4/12 max-w-lg z-10">
      <SageTopLeft />
    </div>
    <div className="absolute z-0 m-3" style={{
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      marginTop: '3.5em'
    }}>
      <div className="shadow-xl flex flex-col items-center justify-center relative md:mx-12" style={{ 
          background: 'rgb(248, 246, 242)', //rgba(150, 150, 150, 0.2) //rgb(23 67 122 / 24%)
          minHeight: '90vh',
          minWidth: '90vw'
      }}>
      <a href="/" className="hidden md:block"><h1 className="gold-text text-xl md:text-4xl absolute top-0 right-0 p-2" style={{ transform: 'rotate(90deg) translate(4em, -2.5em)' }}>JoeAndJayna.com</h1></a>
      <div className="text-body m-8 mt-12 text-center" style={{ maxWidth: '630px' }}>
        <div className="welcome-heading flex flex-col items-center justify-center">
          <p className="font-roman text-3xl md:text-6xl tracking-widest uppercase">Welcome</p>
          <p className="font-cursive text-2xl daggers">to</p>
          <p className="font-roman text-xl tracking-widest uppercase">JoeAndJayna.com</p>
        </div>
        <br />        
        <div>
            <iframe style={{ minWidth: 400 }} height="315" src="https://www.youtube.com/embed/S87-xO5fzRY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
        <div className="jj-logo" />
      </div>
    </div>
    <div className="absolute left-0 bottom-0 p-1">
    </div>
    <div className="fixed hidden md:inline right-0 bottom-0 w-60 md:w-4/12 max-w-lg z-10" style={{ pointerEvents: 'none' }}>
      <SageBottomRight />
    </div>
  </div>
    )
}

export default WeddingVid
